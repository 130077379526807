<template>
    <v-card id="top" class="pa-5 custom-border border">
        <span class="poppins fw600 mb-10 mx-2">
            <div class="fixed poppins f22 fw600 pb-0">
                <v-badge
                    :content="cart.length"
                    :color="cart.length === 0 ? 'transparent' : 'primary'"
                    :offset-x="-4"
                >
                    My List
                </v-badge>
            </div>
            <v-card v-if="cart.length > 0" v-for="item in cart" :key="item.uuid" class="pa-2 my-1 fade custom-border" outlined width="100%" :style="'background-color: transparent'">
                <section class="d-flex align-start flex-row cursor-pointer"  @click="$router.push({name: 'User Other Course Details', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})">
                    <v-img
                        stretch
                        max-height="100"
                        max-width="100"
                        class="mr-1 cursor-pointer"
                        :src="item.image ? item.image.url : require('@/assets/images/default-course-bg.png')"
                        :lazy-src="item.item ? item.image.url : require('@/assets/images/default-course-bg.png')"
                        v-on:error="require('@/assets/images/default-course-bg.png')"/>
                    
                    <v-list-item-content width="70%">
                        <v-list-item-title>
                            {{ item.title }}
                        </v-list-item-title>
                        <span class="f10 fw600 primary--text" v-if="course.is_free || course.price === 0">FREE!</span>
                        <span class="f10 fw600 primary--text" v-if="!course.is_free">PHP {{ course.price ? course.price : 0 }}</span>
                    </v-list-item-content>
                    <v-btn icon small dense @click.stop @click="removeFromList(item.uuid, item.id)" size="10">
                        <v-icon small> mdi-close </v-icon>
                    </v-btn>
                </section>
                <div class="d-flex flex-row justify-end">
                    <v-spacer/>
                    <v-btn 
                        dense
                        small
                        v-if="user_courses.findIndex(_course => _course.uuid === item.uuid) === -1"
                        color="primary" class="f12 mr-0" 
                        @click="(e) => {e.preventDefault, proceedToPayment(item.id, i)}" :loading="checkoutLoading[i]">
                        Proceed to Payment
                    </v-btn>
                </div>
            </v-card>
            <!-- <v-list dense v-if="cart.length > 0">
                <v-list-item v-for="(item, i) in cart" :key="item.uuid" class="d-flex flex-row my-2 px-3">
                    <v-row>
                        <v-col cols="12" md="7" class="d-flex flex-row">
                            <v-img
                                max-height="100"
                                max-width="80"
                                class="mr-2 align-self-start cursor-pointer"
                                :src="item.image ? item.image.url : require('../../../assets/images/default-course-bg.png')"
                                :lazy-src="item.image ? item.image.url : require('../../../assets/images/default-course-bg.png')"
                                v-on:error="require('../../../assets/images/default-course-bg.png')"
                                @click="$router.push({name: 'User Other Course Details', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})"/>
                            <v-container class="d-flex pa-0 flex-column align-start">
                                <v-container class="d-flex flex-column align-start cursor-pointer pa-0"
                                    @click="$router.push({name: 'User Other Course Details', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})">
                                    <section class="poppins fw600">
                                        <div class="truncated">
                                            {{ truncateDescription(item.title, 40) }}
                                        </div>
                                        <div v-if="item.available_until" class="primary-font fw500 f12 py-0">
                                            <span v-if="item.available_until.start_date"> from {{ $dateFormat.mmDDyy(item.available_until.start_date) }} </span>
                                            <span v-if="item.available_until.end_date"> until {{ $dateFormat.mmDDyy(item.available_until.end_date) }} </span>
                                        </div>
                                    </section>
                                </v-container>
                                <v-btn text dense @click="removeFromList(item.uuid, item.id)" class="danger--text poppins pa-0 my-0 text-capitalize f10">
                                    <v-icon small> mdi-close </v-icon>
                                    Remove
                                </v-btn>
                            </v-container>
                        </v-col>
                        <v-col cols="12" md="2" v-if="tenant === 'corporate'">
                            <span class="f16 fw600" v-if="item.is_free">FREE!</span>
                            <span class="f16 fw600" v-if="!item.is_free">PHP {{ item.price ? item.price : 0 }}</span>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-btn 
                            v-if="user_courses.findIndex(_course => _course.uuid === item.uuid) === -1"
                            color="primary" class="f12" @click="proceedToPayment(item.id, i)" :loading="checkoutLoading[i]">Proceed to Payment</v-btn>
                            <v-alert color="success" class="f12" text dense v-else>
                                ENROLLED
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-list-item>
            </v-list> -->
            <section class="" v-else>
                <span class="f14 secondary-2--text"><i>Your list is empty</i></span>
            </section>
        </span>

    </v-card>
</template>

<style scoped>
.v-progress-linear {
    width: 180px !important;
    margin-top: 25vh !important;
}
</style>

<script>
import { mapActions, mapState, mapMutations } from "vuex"
import GalleryCard from "../../../components/user/course/GalleryCard.vue"

export default {
    name: 'landing-home',
    components: {
        GalleryCard
    },
    data: () => ({
        loading: false,
        removeLoading: false,
        checkoutLoading: {},
        errorPrompt: false,
        data: {
            ref_id: 123,
            amount: 100,
            email: 'test@test.test',
            form_id: 175,
            loc_id: 5,
            first_name: 'test',
            middle_name: 'test',
            last_name: 'test',
            notify_url: 'test.test',
            hash: '17e2dcd2e2f1b4cceded06eb1253103a',
            service_name_spicify: 'test',
            service_other_details: 'test'
        },
    }),
    mounted() {
        //this.loading = true
    }, 
    computed: {
        ...mapState({
            course: (state) => state.course,
            tenant: (state) => state.tenant,
            // courses(state) {
            //     return [...state.free_courses, ...state.paid_courses].filter(item => item.uuid !== this.$route.params.uuid)
            // }
        }),
        ...mapState('usr', {
            cart: (state) => state.cart,
            user_courses: (state) => state.courses,
        }),

        getPrice() {
            if(this.cart.length === 0) {
                return 0
            } else {
                let price = 0
                this.cart.forEach(i => {
                    if(!i.is_free) {
                        price += parseInt(i.price ? i.price : 0)
                    }
                })

                return price
            }
        }
    },
    methods: {
        ...mapMutations(['alertMutation']),
        ...mapMutations('usr',['cartMutation']),
        ...mapActions('usr', ['removeFromCart', 'getCoursesAction', 'getCartItems']),

        truncateDescription(desc, length) {
            return desc.length > length ? `${desc.slice(0, length-4)}...` : desc
        },

        removeFromList(uuid, id){
            let filtered = this.cart.filter(i => i.uuid !== uuid)
            //this.cartMutation(filtered)

            if(filtered.length === 0) {
                localStorage.removeItem('cart')
            } else {
                localStorage.setItem('cart', JSON.stringify(filtered))
            }
            this.removeFromCart({course_id: id})
        },

        async proceedToPayment(id, index){
            this.checkoutLoading[index] = true
            let _form = new FormData()
            // this.cart.forEach(_item => {
            //     _form.append('course_id[]', _item.id)
            // })

            _form.append('course_id', id)

            const config = { 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                }
            }

            this.$api.post(`/user/checkout`, _form, config).then(res => {
                if (res.data.hasOwnProperty('mode_of_payment')) {
                    this.checkoutLoading[index] = false
                    this.alertMutation({
                        show: true,
                        text: 'Enrollment Success! Redirecting you to dashboard...',
                        type: "success"
                    })
                    setTimeout(() => {
                        location.href = '/dashboard/'
                        // this.getCoursesAction().then(() => {
                        //     this.otherCoursesAction().then(() => {
                        //         this.getCartItems().then(() => {
                        //             this.$router.push('/')
                        //         })
                        //     })
                        // })
                    }, 1000)
                } else {
                    var newDoc = document.implementation.createHTMLDocument();
                    var parsedContent = document.createElement('div');

                    var parser = new DOMParser();
                    var xmlDoc = parser.parseFromString(res.data, 'text/html');

                    parsedContent.appendChild(xmlDoc.documentElement);
                    newDoc.body.appendChild(parsedContent);
                    
                    var newTab = window.open();
                    newTab.document.write(newDoc.documentElement.outerHTML);

                    this.checkoutLoading[index] = false
                    location.href = '/'
                }
            }).catch(e => {
                if(e.response.status === 404) {
                    this.checkoutLoading[index] = false
                    this.alertMutation({
                        show: true,
                        text: 'Checkout is not available at this moment',
                        type: "error"
                    })
                    // this.errorPrompt = true
                    // this.checkoutLoading = false
                }
            })
            
        }
    },
}
</script>